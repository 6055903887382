import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { SiteMetaData } from '../utils/types'

export const Seo = ({
  title,
  keywords,
  description,
  socialImage,
  metaUrl,
  author,
}: {
  title?: string
  keywords?: string
  description?: string
  socialImage?: string
  metaUrl?: string
  author?: {
    name: string
    twitter: string
  }
}) => {
  return (
    <StaticQuery
      query={seoSiteMetaQuery}
      render={({
        site: { siteMetadata },
      }: {
        site: { siteMetadata: SiteMetaData }
      }) => {
        const metaDescription = description
          ? `${description} :: ${siteMetadata.description}`
          : siteMetadata.description
        const metaTitle = title
          ? `${title} :: ${siteMetadata.title}`
          : siteMetadata.title
        const metaKeywords = keywords
          ? `${keywords}, ${siteMetadata.keywords}`
          : siteMetadata.keywords
        const metaAuthor = author || siteMetadata.author
        const siteTwitter = siteMetadata.social.twitter
        const siteUrl = metaUrl || siteMetadata.siteUrl
        const metaSocialImage = socialImage || siteMetadata.social.image
        return (
          <Helmet
            htmlAttributes={{
              lang: `en`,
            }}
            title={title}
            titleTemplate={`%s | ${siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:url`,
                content: siteUrl,
              },
              {
                property: `og:image`, //update image
                content: metaSocialImage,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:site`,
                content: siteTwitter,
              },
              {
                name: `twitter:creator`,
                content: metaAuthor.twitter,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`, //update image
                content: metaSocialImage,
              },
              {
                name: 'keywords',
                content: metaKeywords,
              },
            ]}
          />
        )
      }}
    />
  )
}

const seoSiteMetaQuery = graphql`
  query seoQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        author {
          name
          summary
          twitter
        }
        social {
          image
          twitter
          facebook
          instagram
          youtube
        }
      }
    }
  }
`
